// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import constant from '../../services/constant'
import apiServices from '../../api/apiservices';

import { cartReset, cartDiscountReset } from '../../store/cart';
import { toast } from 'react-toastify';

import AccountPageAddresses from '../account/AccountPageAddresses'


import Loader from '../shared/Loader'



class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            payment: 'bank',
            userToken: localStorage.getItem(constant.CONSTANTS.USER_TOKEN),
            isTermsCheck: false,
            selectAddressVal: "",
            isTermError: false,
            isAddressError: false,
            loadingCustom: false,
            guestFirstName: '',
            guestLastName: '',
            guestEmail: '',
            guestMobile: '',
            guestAddress: '',
            errorFields: {}
        };

        this.getData = this.getData.bind(this);
        // this.getData().bin 
        // const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)
        // console.log(token);

        // if (token) {
        //     this.setState({ userToken: token })
        // }

    }
    getData = (val) => {
        // do not forget to bind getData in constructor

        // console.log("PARENT VALUE");
        // console.log(val);

        this.setState({ selectAddressVal: val });

    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={extraLine.price} /></td>
            </tr>
        ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Total</th>
                        <td><Currency value={cart.total} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;

        const payments = this.payments.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={true}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }


    render() {
        const { cart } = this.props;
        const { guestName, guestEmail, guestMobile, guestAddress, guestFirstName,
            guestLastName } = this.state;

        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }

        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Shopping Cart', url: '/shop/cart' },
            { title: 'Checkout', url: '' },
        ];

        const placeOrder = () => {
            this.setState({ loadingCustom: true })

            const { cartReset } = this.props
            // if (userToken) {


            this.setState({
                isTermError: false
            })
            this.setState({
                isAddressError: false
            })


            if (!this.state.isTermsCheck) {
                this.setState({
                    isTermError: true
                })
                this.setState({ loadingCustom: false })
                return false;
            }

            var cartData = []
            console.log("cart")
            console.log("cart")
            console.log("cart")
            console.log(cart)
            for (let i = 0; i < cart.items.length; i++) {
                var obj = {
                    id: parseInt(cart.items[i].product.id),
                    quantity: cart.items[i].quantity,
                    price: cart.items[i].price,
                    productId: cart.items[i].product.variants[0].id
                }
                cartData.push(obj)
            }

            // console.log(cartData)
            // return false

            setTimeout(async () => {
                var formData = new FormData();

                if (userToken) {
                    formData.append('token', userToken);
                    // formData.append('guest', null);

                    if (this.state.selectAddressVal == "") {
                        this.setState({
                            isAddressError: true
                        })
                        this.setState({ loadingCustom: false })
                        return false;
                    }


                } else {
                    formData.append('token', null);


                    if (handleRegisterValidation()) {

                        formData.append('guest', JSON.stringify({
                            first_name: guestFirstName,
                            last_name: guestLastName,
                            email_address: guestEmail,
                            phone_number: guestMobile,
                            address: guestAddress
                        }));

                    } else {
                        toast.error("Please fill billing details correctly");
                        this.setState({ loadingCustom: false })
                        return false
                    }
                }

                for (let i = 0; i < cart.extraLines.length; i++) {
                    if (cart.extraLines[i].type == "discount") {
                        formData.append('discount_code', cart.discountCode);
                        formData.append('discount_total', cart.extraLines[i].price);
                    }
                    if (cart.extraLines[i].type == "delivery") {
                        formData.append('delivery_charges', cart.extraLines[i].price);
                    }
                }


                formData.append('order_type', "Website");
                formData.append('sub_total', cart.subtotal);
                formData.append('grand_total', cart.total);
                formData.append('address_id', this.state.selectAddressVal);
                formData.append('cart_data', JSON.stringify(cartData));

                for (var pair of formData.entries()) {
                    console.log(pair)
                }

                apiServices.postRequestCheck({ url: constant.API.BASE_URL + constant.API.CHECKOUT, data: formData }).then(res => {
                    console.log("Salman")

                    res.json().then(resp => {
                        this.setState({ loadingCustom: false })

                        if (resp.message == "Invoice Created Successfully") {

                            this.props.history.push('/shop/checkout/success/' + resp.data);

                            cartReset()
                            cartDiscountReset();
                        }
                    })
                });
            }, 1000);

        }


        const handleInputChange = (e, valProperty) => {
            this.setState({ [valProperty]: e })
        }

        const handleRegisterValidation = () => {

            // console.log(/\S+@\S+\.\S+/.test(email))

            // guestName
            // guestEmail
            // guestMobile
            // guestAddress


            var isValid = true;
            let errorFields = {}

            if (guestFirstName.length < 1) {
                errorFields.guestFirstName = true
                isValid = false
            }
            if (guestLastName.length < 1) {
                errorFields.guestLastName = true
                isValid = false
            }

            if (guestAddress.length < 1) {
                errorFields.guestAddress = true
                isValid = false
            }

            if (guestMobile.length < 11) {
                errorFields.guestMobile = true
                isValid = false
            }

            if (!(/\S+@\S+\.\S+/.test(guestEmail)) || guestEmail.length < 1) {
                errorFields.guestEmail = true
                isValid = false
            }

            this.setState({ errorFields: errorFields })

            return isValid;

        }


        const { userToken, isTermError, isAddressError, loadingCustom, errorFields } = this.state;

        return (

            < React.Fragment >
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />

                <div className="checkout block">
                    <div className="container">
                        <div className="row">

                            {!userToken ?
                                <div className="col-12 mb-3">
                                    <div className="alert alert-primary alert-lg">
                                        Returning customer?
                                        {' '}
                                        <Link to="/account/login">Click here to login</Link>
                                    </div>
                                </div>
                                :
                                <div className="col-12 col-lg-6 col-xl-7">
                                    <div className="card mb-lg-0">
                                        <div className="card-body">
                                            <AccountPageAddresses sendData={this.getData} isCheckout={true} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {!userToken ?
                                <div className="col-12 col-lg-6 col-xl-7">
                                    <div className="card mb-lg-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Guest checkout billing details</h3>
                                            <div className="form-group">
                                                <label htmlFor="checkout-name">
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-company-name"
                                                    placeholder="Enter First Name"
                                                    onChange={e => handleInputChange(e.target.value, 'guestFirstName')}

                                                />
                                                {errorFields.guestFirstName ? <span className="error-field">Please enter first name</span> : null}
                                            </div>


                                            <div className="form-group">
                                                <label htmlFor="checkout-name">
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-company-name"
                                                    placeholder="Enter Last Name"
                                                    onChange={e => handleInputChange(e.target.value, 'guestLastName')}

                                                />
                                                {errorFields.guestFirstName ? <span className="error-field">Please enter last name</span> : null}
                                            </div>


                                            <div className="form-group">
                                                <label htmlFor="checkout-street-address"> Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-street-address"
                                                    placeholder="Street Address"
                                                    onChange={e => handleInputChange(e.target.value, 'guestAddress')}

                                                />
                                                {errorFields.guestAddress ? <span className="error-field">Please enter address</span> : null}
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-email">Email address</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="checkout-email"
                                                        placeholder="Email address"
                                                        onChange={e => handleInputChange(e.target.value, 'guestEmail')}
                                                    />
                                                    {errorFields.guestEmail ? <span className="error-field">Please enter correct email address</span> : null}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-phone">Mobile Number</label>
                                                    <input type="text" className="form-control" id="checkout-phone" placeholder="03XX XXXXXXX"
                                                        maxLength={11}
                                                        onChange={e => handleInputChange(e.target.value, 'guestMobile')}
                                                    />
                                                    {errorFields.guestMobile ? <span className="error-field">Please enter correct mobile number</span> : null}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-divider" />
                                    </div>
                                </div>

                                : null}
                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0 place-order-section">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Your Order</h3>
                                        {this.renderCart()}
                                        {this.renderPaymentsList()}
                                        <div className="checkout__agree form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-terms"
                                                            value={this.state.isTermsCheck}
                                                            onChange={e => this.setState({ isTermsCheck: !this.state.isTermsCheck })}
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-terms">
                                                    I have read and agree to the website
                                                    <Link to="/site/terms"> terms and conditions</Link>
                                                    *
                                                </label>

                                                {isTermError ? <span className="error-field">Please check terms and conditions</span> : null}
                                                {isAddressError ? <span className="error-field">Please select an address before placing order</span> : null}
                                                {/* <p className="discalimer-txt">* Prices will be confirmed on the confirmation call by Mandora</p> */}

                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary btn-xl btn-block text-color-white" onClick={placeOrder}>Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(loadingCustom) ? <Loader /> : null}

                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartReset,
    cartDiscountReset

};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
