export default {

    API: {

        // BASE_URL: "https://mandoraadmin.automania-inc.ca/api/", //BETA

      //  BASE_URL: "https://admin.themandorastore.com/api/", //LIVE

        BASE_URL: "https://mandoraadmin.meridiantdigital.com/api/", //LIVE


        GET_CATEGORY: "categories?",
        GET_CATEGORY_BY_SLUG: "categories/",
        GET_PRODUCT_BY_SLUG: "products/",
        GET_PRODUCT_LIST: "products?",
        GET_BANNERS: "banners",

        GET_HEADERS: "menus?title=header",

        REGISTER_USER: "register",
        LOGIN_USER: "login",
        VERIFY_USER: "verify?token=",
        GET_ORDER_HISTORY: "orderhistory",

        RESET_PASSWORD_TOKEN: "reset?token=",

        CONTACT_US: "contact",
        FORGET_PASSWORD: "forgot",
        RESET_PASSWORD: "resetpassword",
        EDIT_PROFILE: "profile",
        CHANGE_PASSWORD: "changepassword",
        GET_CUSTOMER_ADDRESS: "customeraddress?token=",
        ADD_CUSTOMER_ADDRESS: "customeraddaddress",
        EDIT_CUSTOMER_ADDRESS: "customereditaddress",
        DELETE_CUSTOMER_ADDRESS: "customerdeleteaddress?token=",
        GET_ORDER_DETAIL: "invoice?token=",
        CANCEL_ORDER: "cancelorder?token=",

        SUBS_TO_NEWSLETTER: "subscribe",


        GET_ABOUT_US: "page?url=about-us",
        GET_TC: "page?url=t-c",
        GET_FAQ: "faq",

        CHECKOUT: "checkout",

        APPLY_DISCOUNT: "discount",
        GET_SETTING: "getsetting?title=",


        GET_WISHLIST: "customerwishlist?token=",
        ADD_PRODUCT_WISHLIST: "customeraddwishlist",
        REMOVE_PRODUCT_WISHLIST: "customerdeletewishlist?token="
    },

    CONSTANTS: {
        USER_KEY: 'USER_KEY',
        IS_REMEMBER: "IS_REMEMBER",
        USER_TOKEN: "USER_TOKEN",
        USER_DATA: 'USER_DATA',
        USER_LOGGED_IN: 'USER_LOGGED_IN',

    }

}