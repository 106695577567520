import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_RESET, CART_DISCOUNT_RESET, CART_UPDATE_DELIVERY_CHARGES } from './cartActionTypes';

export function cartAddItemSuccess(product, options = [], quantity = 1) {

    console.log(product)
    toast.success(`Product "${product.name}" added to cart!`);

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(data) {
    return {
        type: CART_UPDATE_QUANTITIES,
        data,
    };
}

export function cartStateReset() {
    return {
        type: CART_RESET,
    };
}

export function cartStateDiscountReset() {
    return {
        type: CART_DISCOUNT_RESET,
    };
}


export function cartUpdateDeliveryChargesSuccess(data) {
    return {
        type: CART_UPDATE_DELIVERY_CHARGES,
        data,
    };
}






export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub

    console.log(product)
    
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(data) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(data));
                resolve();
            }, 500);
        })
    );
}

export function cartReset() {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartStateReset());
                resolve();
            }, 500);
        })
    );
}


export function cartDiscountReset() {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartStateDiscountReset());
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateDeliveryCharges(data) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                console.log("cartUpdateDeliveryCharges 11")
                dispatch(cartUpdateDeliveryChargesSuccess(data));
                resolve();
            }, 500);
        })
    );
}
