// react
import React from 'react';

// data stubs
import theme from '../../data/theme';
import SocialLinks from '../shared/SocialLinks';

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            {/* <h5 className="footer-contacts__title">Contact Us</h5>

            <div className="footer-contacts__text">
                Contact us for any queries.
            </div> */}

            <img className="footer-logo-img" src="images/footer-logo.png" alt="" />

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />


            {/* <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas white-color" />
                    {theme.contacts.address}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope white-color" />
                    {theme.contacts.email}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt white-color" />
                    {`${theme.contacts.phone}`}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock white-color" />
                    {theme.contacts.timing}

                </li>
            </ul> */}
        </div>
    );
}
