export default [
    { image: 'images/brands/1.jpg' },
    { image: 'images/brands/2.jpg' },
    { image: 'images/brands/3.jpg' },
    { image: 'images/brands/4.jpg' },
    { image: 'images/brands/5.jpg' },
    { image: 'images/brands/6.jpg' },
    { image: 'images/brands/7.jpg' },
    { image: 'images/brands/8.jpg' },
    { image: 'images/brands/9.jpg' },
    { image: 'images/brands/10.jpg' },
    { image: 'images/brands/11.jpg' },
    { image: 'images/brands/12.jpg' },
    { image: 'images/brands/13.jpg' },
];
